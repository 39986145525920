<template>
<div>
    <table style="width:80%" class="table-sm">
        <tr>
            <td v-if="loggedinuser!=null && loggedinuser.role==3" colspan="7" style="border:none;">
            <button type="button" style="float:right;background-color:#495b79;border-radius:4px;" class="btn-info" @click="add()">Add</button>
            </td>
        </tr>
        <tr v-for="(item) in operations" v-bind:key="item.id" style="float:left;background-color: antiquewhite;margin-left:5px;margin-right:5px;width:30%;" >
            <td style="width:100%;"><user-operation-checkbox :operationid="item.id" v-if="appid==0"></user-operation-checkbox>
                <user-app-operation-checkbox :operationid="item.id" v-if="appid==1"></user-app-operation-checkbox>
            {{ item.operation }}({{ item.id }})
            </td>
            <td v-if="loggedinuser!=null && loggedinuser.role==3"><button type="button" class="btn btn-outline-danger btn-sm" style="border:none;" @click="deleteoperation(item)"><i class="fa fa-times"></i></button></td>
        </tr>
    </table>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import UserOperationCheckbox from './UserOperationCheckbox.vue'
import UserAppOperationCheckbox from './UserAppOperationCheckbox.vue'
export default {
    components:{UserOperationCheckbox,UserAppOperationCheckbox},
    props:['moduleid','appid'],
    computed:{
        ...mapGetters([
		      'accessmodules','loggedinuser'
	    ]),
        operations(){
            let found = this.accessmodules.find(block=>block.id==this.moduleid)
            if(typeof found!=='undefined'){
                return found.operations;
            }
            return [];
        }
    },
    methods:{
        deleteoperation(item){
            let param = {id:item.id}
            this.$http.post('api/access/modules/operation/delete',param)
			.then(response=>this.processResponse(response.data))
			.catch(error=>console.log(error));
        },
        processResponse(data){
            this.$store.commit('updateappendaccessmodules',data)
        },
        add(){
            let obj ={id:0,moduleid:this.moduleid,operation:''}
            this.$store.commit('assignedititem',obj)
            this.$store.commit('assignviewno',3)
        },
       
    }
}
</script>